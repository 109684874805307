import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  FormLabel,
  HStack,
  Input,
  Select as ChakraSelect,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import FormControlRHF from "../../../../components/ReactHookForm/FormControlRHF";
import ObserverRHF from "../../../../components/ReactHookForm/ObserverRHF";
import {
  BOOKINGS_NATURE_LIST,
  CARTS_BOOKINGS_WORKFLOW_VALUE_DIRECT,
  CARTS_BOOKINGS_WORKFLOW_VALUE_SYSTEM,
} from "../../../../constants/bookings";
import { useApi } from "../../../../hooks/useApi";
import useTranslate from "../../../../hooks/useTranslate";
import generateApiUrl from "../../../../libraries/utils/generateApiUrl";
import dayjs from "dayjs";
import { memo, useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { BookingRemoveConfirmationModal } from "./BookingRemoveConfirmationModal";
import { useFormContext } from "react-hook-form";
import { BookingRequestChangeFieldMessage } from "./BookingRequestChangeFieldMessage";
import BookingUpdateRecursiveDiffList from "../../../Messages/Message/Types/BookingUpdateRecursiveDiffList";
import FormObserver from "../../../../components/ReactHookForm/FormObserver";
import { CheckinCheckoutInput } from "../../../../components/Form/CheckinCheckoutInput";
import { canPerformRoomInfoCreateBooking } from "../../../../authorizations/RoomInfo/CreateBooking";
import useCanPerform from "../../../../hooks/useCanPerform";
import { renderRoomSelectOption } from "../../../../components/Room/Select/renderOption";
import { Select } from "../../../../components/Form/Select/Select";

/**
 * @typedef {"children" | "adults" | "babies" | "pets" | "checkin" | "checkout" | "periods_amount"} BookingRequestUpdateField
 */

export const BookingFields = memo(
  /**
   * @typedef {object} Props
   * @property {(data?: import("./useBookingEstimate").RequestEstimateParams) => void} requestEstimate
   * @property {number} bookingIndex
   * @property {import("react-hook-form").UseFieldArrayRemove} [bookingsRemove]
   * @property {boolean} areFieldsMissing
   * @property {string} roomId
   * @property {number} [bookingId]
   * @property {number | null} [temporaryPeriodsAmount]
   * @property {import("react").Dispatch<import("react").SetStateAction<number | null>>} [setTemporaryPeriodsAmount]
   * @property {boolean} [showRoomAutocomplete]
   * @property {keyof typeof import("../../../../constants/carts").CARTS_WORKFLOWS} workflow
   * // TODO : Add a generic type for initialValues and newValues
   * @property {{ initialValues: object, newValues: object, message: string}} [requestedChangeValues]
   * @property {"update" | "create"} [context]
   */

  /**
   * @param {Props} props
   */
  function BookingFields({
    requestEstimate,
    bookingIndex,
    bookingsRemove,
    areFieldsMissing,
    roomId,
    bookingId,
    temporaryPeriodsAmount,
    setTemporaryPeriodsAmount,
    showRoomAutocomplete = true,
    workflow,
    requestedChangeValues,
    context = "create",
  }) {
    const intl = useIntl();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const { getValues, setValue, control } = useFormContext();

    const translate = useTranslate();

    const [bookingRequestedChangeValues, setBookingRequestedChangeValues] =
      useState(requestedChangeValues?.newValues);

    /**
     * @param {object} param
     * @param {BookingRequestUpdateField} param.fieldName
     */
    const getBookingUpdateDifferencePerField = useCallback(
      ({ fieldName }) => {
        if (
          bookingRequestedChangeValues?.[fieldName] !==
          getValues(`data.bookings.${bookingIndex}.${fieldName}`)
        ) {
          return bookingRequestedChangeValues?.[fieldName];
        }

        return null;
      },
      [bookingIndex, bookingRequestedChangeValues, getValues],
    );

    /**
     * @param {object} param
     * @param {BookingRequestUpdateField} param.fieldName
     */
    function acceptBookingUpdateRequestByField({ fieldName }) {
      setValue(
        `data.bookings.${bookingIndex}.${fieldName}`,
        bookingRequestedChangeValues[fieldName],
      );

      setBookingRequestedChangeValues((previousValue) => {
        return {
          ...previousValue,
          [fieldName]: undefined,
        };
      });

      requestEstimate();
    }

    function handleRefuseNewPeriodAmount() {
      setTemporaryPeriodsAmount?.(null);
    }

    function handleAcceptNewPeriodAmount() {
      if (temporaryPeriodsAmount) {
        setValue("data.bookings.0.periods_amount", temporaryPeriodsAmount, {
          shouldDirty: true,
        });
        setValue("meta.dont_refresh_periods_amount", false, {
          shouldDirty: true,
        });
        setValue("meta.force_refresh_periods_amount", true, {
          shouldDirty: true,
        });
        requestEstimate({ forcePeriodsAmount: true });
      }
    }

    const validateCheckinMaxTime = useCallback(
      (value, checkinMinTime) => {
        if (value < checkinMinTime) {
          return intl.formatMessage({
            defaultMessage:
              "L'heure maximale d'arrivée doit être supérieure à l'heure minimale d'arrivée",
          });
        }

        return true;
      },
      [intl],
    );

    const validateCheckoutMaxTime = useCallback(
      (value, checkoutMinTime) => {
        if (value < checkoutMinTime) {
          return intl.formatMessage({
            defaultMessage:
              "L'heure maximale de départ doit être supérieure à l'heure minimale de départ",
          });
        }

        return true;
      },
      [intl],
    );

    /** @type {import("../../../../hooks/useApi").UseApi<import("../../../../types/Room").Room>} */
    const { swrResponse: roomResponse } = useApi(
      generateApiUrl({
        id: "@rooms.view",
        parameters: {
          roomId: roomId,
        },
        query: {
          fields: ["rooms.services", "rooms.hotel", "hotels.pets"],
        },
      }),
      {
        swrConfig: {
          onSuccess: (response) => {
            setValue(
              `data.bookings.${bookingIndex}.room_name`,
              translate(response.data?.name) ?? "",
            );
          },
        },
      },
    );

    /** @type {import("../../../../hooks/useApi").UseApi<import("../../../../types/Customer").Customer>} */
    const { swrResponse } = useApi(
      generateApiUrl({
        id: "@auth.me",
        query: {
          fields: ["users.customers.rooms_infos"],
        },
      }),
    );

    const roomInfosList = swrResponse?.data?.data?.rooms_infos;

    const canPerform = useCanPerform();

    const filteredRoomInfosList = roomInfosList?.filter((roomInfo) =>
      canPerformRoomInfoCreateBooking({ resource: roomInfo, canPerform }),
    );

    const roomsOptions = useMemo(() => {
      /** @type {import("../../../../components/Form/Select/Select").SelectOption<number, import("../../../../components/Room/Select/renderOption").RoomsSelectOptionData>[]} */
      const options = (filteredRoomInfosList ?? []).map((roomInfo) => ({
        value: roomInfo.id,
        label: [roomInfo.hlo, roomInfo.name].filter(Boolean).join(" - "),
        data: {
          title: roomInfo.hlo,
          name: roomInfo.name ?? "",
          picture: roomInfo.picture,
        },
      }));
      return options;
    }, [filteredRoomInfosList]);

    const handleCheckinCheckoutChange = useCallback(
      ({ checkin, checkout }) => {
        setValue(`data.bookings.${bookingIndex}.checkin`, checkin, {
          shouldDirty: true,
        });
        setValue(`data.bookings.${bookingIndex}.checkout`, checkout, {
          shouldDirty: true,
        });

        requestEstimate();
      },
      [bookingIndex, requestEstimate, setValue],
    );

    const getCheckinCheckoutIncludeRestricted = useMemo(() => {
      if (workflow === CARTS_BOOKINGS_WORKFLOW_VALUE_DIRECT) {
        return true;
      }

      if (
        workflow === CARTS_BOOKINGS_WORKFLOW_VALUE_SYSTEM &&
        context === "update"
      ) {
        return false;
      }

      return false;
    }, [context, workflow]);

    const getCheckinCheckoutIncludeOnPast = useMemo(() => {
      if (
        workflow === CARTS_BOOKINGS_WORKFLOW_VALUE_DIRECT ||
        workflow === CARTS_BOOKINGS_WORKFLOW_VALUE_SYSTEM
      ) {
        return true;
      }

      return false;
    }, [workflow]);

    const getCheckinCheckoutIncludeWithoutPrices = useMemo(() => {
      return true;
    }, []);

    const selectedRoom = useMemo(() => {
      return roomResponse?.data?.data ?? null;
    }, [roomResponse?.data?.data]);

    const selectedRoomCapacity =
      selectedRoom?.legal_capacity ?? selectedRoom?.capacity;

    const allowPets = selectedRoom?.hotel?.pets?.allow_pets;

    return (
      <>
        {requestedChangeValues && (
          <Alert status="info">
            <Stack spacing="1rem">
              {requestedChangeValues.message && (
                <Text>{requestedChangeValues.message}</Text>
              )}
              <SimpleGrid columns={{ base: 1, md: 2 }}>
                <Box>
                  <Text fontWeight="700">
                    {intl.formatMessage({ defaultMessage: "Avant" })}
                  </Text>
                  <BookingUpdateRecursiveDiffList
                    diffObject={requestedChangeValues.initialValues}
                  />
                </Box>
                <Box>
                  <Text fontWeight="700">
                    {intl.formatMessage({ defaultMessage: "Après" })}
                  </Text>
                  <BookingUpdateRecursiveDiffList
                    diffObject={requestedChangeValues.newValues}
                  />
                </Box>
              </SimpleGrid>
            </Stack>
          </Alert>
        )}

        <HStack justifyContent="space-between">
          <Text variant="h4" fontWeight="500">
            {intl.formatMessage({
              defaultMessage: "Information sur le séjour",
            })}
          </Text>

          <ObserverRHF
            names={[`data.bookings`]}
            render={({ values: [bookings] }) => (
              <>
                {bookings.length > 1 && (
                  <Button variant="outline" onClick={onOpen} colorScheme="red">
                    {intl.formatMessage({ defaultMessage: "Supprimer" })}
                  </Button>
                )}
              </>
            )}
          />

          <BookingRemoveConfirmationModal
            isOpen={isOpen}
            onClose={onClose}
            onRemove={() => bookingsRemove?.(bookingIndex)}
          />
        </HStack>

        <SimpleGrid columns={{ base: 1, md: 2 }} spacing="1rem">
          {showRoomAutocomplete && (
            <FormControlRHF
              control={control}
              label={intl.formatMessage({
                defaultMessage: "Filtrer par hébergement",
              })}
              rules={{
                required: true,
                onChange: () => {
                  requestEstimate();
                },
              }}
              name={`data.bookings.${bookingIndex}.room_id`}
              renderWithFormControl={(field) => (
                <Select
                  {...field}
                  options={roomsOptions}
                  w={{ base: "100%", lg: "500px" }}
                  renderOption={renderRoomSelectOption}
                />
              )}
            />
          )}

          <FormControlRHF
            control={control}
            name={`data.bookings.${bookingIndex}.nature`}
            rules={{
              onChange: () => {
                requestEstimate();
              },
            }}
            label={intl.formatMessage({
              defaultMessage: "Nature de la réservation",
            })}
            helperText={intl.formatMessage({
              defaultMessage: "Champ à but statistiques seulement",
            })}
            renderWithFormControl={(fields) => (
              <ChakraSelect {...fields}>
                {BOOKINGS_NATURE_LIST?.map?.((nature) => (
                  <option value={nature.id} key={nature.id}>
                    {intl.formatMessage(
                      {
                        defaultMessage:
                          "{nature, select, par {particulier} pro {professionnel} other {}}",
                      },
                      { nature: nature.id },
                    )}
                  </option>
                ))}
              </ChakraSelect>
            )}
          />
        </SimpleGrid>

        <ObserverRHF
          names={[
            `data.bookings.${bookingIndex}.adults`,
            `data.bookings.${bookingIndex}.children`,
            `data.bookings.${bookingIndex}.pets`,
          ]}
          render={({ values: [adults, children, pets] }) => (
            <>
              <Stack spacing="1rem">
                {selectedRoomCapacity &&
                  Number(adults) + Number(children) > selectedRoomCapacity && (
                    <Alert status="warning">
                      <AlertIcon />

                      <AlertDescription>
                        {intl.formatMessage({
                          defaultMessage:
                            "Vous dépassez la capacité pour laquelle l'hébergement a été labellisé.",
                        })}
                      </AlertDescription>
                    </Alert>
                  )}

                {!allowPets && pets > 0 && (
                  <Alert status="warning">
                    <AlertIcon />

                    <AlertDescription>
                      {intl.formatMessage({
                        defaultMessage:
                          "Votre hébergement est configuré pour ne pas accepter des animaux.",
                      })}
                    </AlertDescription>
                  </Alert>
                )}
              </Stack>
            </>
          )}
        />

        <SimpleGrid columns={{ base: 1, md: 3 }} spacing="1rem">
          <Stack spacing=".5rem">
            <FormControlRHF
              control={control}
              rules={{
                required: true,
                min: 1,
                onChange: () => {
                  requestEstimate();
                },
              }}
              type="number-input"
              label={intl.formatMessage({
                defaultMessage: "Nombre d'adultes",
              })}
              name={`data.bookings.${bookingIndex}.adults`}
            />

            {getBookingUpdateDifferencePerField({ fieldName: "adults" }) && (
              <BookingRequestChangeFieldMessage
                newValue={getBookingUpdateDifferencePerField({
                  fieldName: "adults",
                })}
                onAcceptBookingUpdateRequestByField={() =>
                  acceptBookingUpdateRequestByField({ fieldName: "adults" })
                }
              />
            )}
          </Stack>
          <Stack spacing=".5rem">
            <FormControlRHF
              control={control}
              type="number-input"
              label={intl.formatMessage({
                defaultMessage: "Nombre d'enfants",
              })}
              rules={{
                min: 0,
                onChange: () => {
                  requestEstimate();
                },
              }}
              helperText={
                !getBookingUpdateDifferencePerField({
                  fieldName: "children",
                }) &&
                intl.formatMessage({
                  defaultMessage: "De 2 à 18 ans",
                })
              }
              name={`data.bookings.${bookingIndex}.children`}
            />

            {getBookingUpdateDifferencePerField({ fieldName: "children" }) && (
              <BookingRequestChangeFieldMessage
                newValue={getBookingUpdateDifferencePerField({
                  fieldName: "children",
                })}
                onAcceptBookingUpdateRequestByField={() =>
                  acceptBookingUpdateRequestByField({ fieldName: "children" })
                }
              />
            )}
          </Stack>
          <Stack spacing=".5rem">
            <FormControlRHF
              control={control}
              label={intl.formatMessage({
                defaultMessage: "Nombre de bébés",
              })}
              rules={{
                min: 0,
                onChange: () => {
                  requestEstimate();
                },
              }}
              type="number-input"
              helperText={
                !getBookingUpdateDifferencePerField({ fieldName: "babies" }) &&
                intl.formatMessage({
                  defaultMessage: "Moins de 2 ans",
                })
              }
              name={`data.bookings.${bookingIndex}.babies`}
            />
            {getBookingUpdateDifferencePerField({ fieldName: "babies" }) && (
              <BookingRequestChangeFieldMessage
                newValue={getBookingUpdateDifferencePerField({
                  fieldName: "babies",
                })}
                onAcceptBookingUpdateRequestByField={() =>
                  acceptBookingUpdateRequestByField({ fieldName: "babies" })
                }
              />
            )}
          </Stack>
        </SimpleGrid>

        <SimpleGrid columns={{ base: 1, md: 2 }} spacing="1rem">
          <Stack spacing=".5rem">
            <FormControlRHF
              control={control}
              type="number-input"
              label={intl.formatMessage({
                defaultMessage: "Nombre d'animaux",
              })}
              rules={{
                min: 0,
                onChange: () => {
                  requestEstimate();
                },
              }}
              name={`data.bookings.${bookingIndex}.pets`}
            />

            {getBookingUpdateDifferencePerField({ fieldName: "pets" }) && (
              <BookingRequestChangeFieldMessage
                newValue={getBookingUpdateDifferencePerField({
                  fieldName: "pets",
                })}
                onAcceptBookingUpdateRequestByField={() =>
                  acceptBookingUpdateRequestByField({ fieldName: "pets" })
                }
              />
            )}
          </Stack>

          <ObserverRHF
            names={[`data.bookings.${bookingIndex}.pets`]}
            render={({ values: [pets] }) => (
              <FormControlRHF
                control={control}
                type="textarea"
                label={intl.formatMessage({
                  defaultMessage: "Accueil des animaux",
                })}
                placeholder={intl.formatMessage({
                  defaultMessage: "Précisez les animaux",
                })}
                withCharactersCounter={true}
                isDisabled={Number(pets) === 0}
                rules={{
                  maxLength: 3000,
                  required: Number(pets) > 0,
                }}
                name={`data.bookings.${bookingIndex}.pets_info`}
              />
            )}
          />
        </SimpleGrid>

        <Stack spacing=".5rem">
          <FormObserver
            control={control}
            name={`data.bookings.${bookingIndex}.room_id`}>
            {(roomId) => (
              <>
                {roomId && (
                  <FormObserver
                    control={control}
                    name={`data.bookings.${bookingIndex}.checkin`}>
                    {(checkin) => (
                      <FormObserver
                        control={control}
                        name={`data.bookings.${bookingIndex}.checkout`}>
                        {(checkout) => (
                          <>
                            <FormLabel mb="0">
                              {intl.formatMessage({
                                defaultMessage: "Sélectionnez les dates",
                              })}
                            </FormLabel>
                            {(workflow ===
                              CARTS_BOOKINGS_WORKFLOW_VALUE_SYSTEM ||
                              workflow ===
                                CARTS_BOOKINGS_WORKFLOW_VALUE_DIRECT) && (
                              <CheckinCheckoutInput
                                roomId={Number(roomId)}
                                bookingId={bookingId ?? null}
                                capacity={1}
                                checkin={checkin}
                                checkout={checkout}
                                workflow={workflow}
                                includeRestricted={
                                  getCheckinCheckoutIncludeRestricted
                                }
                                includeOnPast={getCheckinCheckoutIncludeOnPast}
                                includeWithoutPrices={
                                  getCheckinCheckoutIncludeWithoutPrices
                                }
                                onChange={handleCheckinCheckoutChange}
                              />
                            )}
                          </>
                        )}
                      </FormObserver>
                    )}
                  </FormObserver>
                )}
              </>
            )}
          </FormObserver>

          <SimpleGrid columns={2} spacing="1rem">
            {getBookingUpdateDifferencePerField({
              fieldName: "checkin",
            }) && (
              <BookingRequestChangeFieldMessage
                newValue={dayjs(
                  getBookingUpdateDifferencePerField({
                    fieldName: "checkin",
                  }),
                ).format("DD/MM/YYYY")}
                onAcceptBookingUpdateRequestByField={() =>
                  acceptBookingUpdateRequestByField({
                    fieldName: "checkin",
                  })
                }
              />
            )}

            {getBookingUpdateDifferencePerField({
              fieldName: "checkout",
            }) && (
              <BookingRequestChangeFieldMessage
                newValue={dayjs(
                  getBookingUpdateDifferencePerField({
                    fieldName: "checkout",
                  }),
                ).format("DD/MM/YYYY")}
                onAcceptBookingUpdateRequestByField={() =>
                  acceptBookingUpdateRequestByField({
                    fieldName: "checkout",
                  })
                }
              />
            )}
          </SimpleGrid>
        </Stack>

        {areFieldsMissing && (
          <Alert status="info">
            <AlertIcon />

            <AlertDescription>
              {intl.formatMessage({
                defaultMessage:
                  "Pour poursuivre, veuillez renseigner au minimum les champs « Hébergement », « Nature », « Date d’arrivée », « Date de départ » et « Nombre d’adultes ».",
              })}
            </AlertDescription>
          </Alert>
        )}

        <SimpleGrid columns={{ base: 1, md: 2 }} spacing="1rem">
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing="1rem">
            <FormControlRHF
              name={`data.bookings.${bookingIndex}.arrival_min`}
              control={control}
              type="text"
              rules={{
                required: true,
              }}
              isDisabled={areFieldsMissing}
              inputProps={{
                type: "time",
              }}
              label={intl.formatMessage({
                defaultMessage: "Heure minimale d'arrivée",
              })}
            />

            <ObserverRHF
              names={[`data.bookings.${bookingIndex}.arrival_min`]}
              render={({ values: [arrivalMinTime] }) => (
                <FormControlRHF
                  control={control}
                  type="text"
                  inputProps={{
                    type: "time",
                  }}
                  isDisabled={areFieldsMissing}
                  rules={{
                    required: true,
                    validate: (value) =>
                      validateCheckinMaxTime(value, arrivalMinTime),
                  }}
                  name={`data.bookings.${bookingIndex}.arrival_max`}
                  label={intl.formatMessage({
                    defaultMessage: "Heure maximale d'arrivée",
                  })}
                />
              )}
            />
          </SimpleGrid>

          <SimpleGrid columns={{ base: 1, md: 2 }} spacing="1rem">
            <FormControlRHF
              name={`data.bookings.${bookingIndex}.departure_min`}
              control={control}
              type="text"
              rules={{
                required: true,
              }}
              isDisabled={areFieldsMissing}
              inputProps={{
                type: "time",
              }}
              label={intl.formatMessage({
                defaultMessage: "Heure minimale de départ",
              })}
            />

            <ObserverRHF
              names={[`data.bookings.${bookingIndex}.departure_min`]}
              render={({ values: [departureMinTime] }) => (
                <FormControlRHF
                  control={control}
                  type="text"
                  inputProps={{
                    type: "time",
                  }}
                  isDisabled={areFieldsMissing}
                  rules={{
                    required: true,
                    validate: (value) =>
                      validateCheckoutMaxTime(value, departureMinTime),
                  }}
                  name={`data.bookings.${bookingIndex}.departure_max`}
                  label={intl.formatMessage({
                    defaultMessage: "Heure maximale de départ",
                  })}
                />
              )}
            />
          </SimpleGrid>
        </SimpleGrid>

        <FormControlRHF
          control={control}
          type="textarea"
          label={intl.formatMessage({
            defaultMessage: "Note client",
          })}
          placeholder={intl.formatMessage({
            defaultMessage: "Note à destination du client",
          })}
          isDisabled={areFieldsMissing}
          withCharactersCounter={true}
          rules={{
            maxLength: 3000,
          }}
          name={`data.bookings.${bookingIndex}.public_comment`}
        />

        <FormControlRHF
          control={control}
          type="textarea"
          label={intl.formatMessage({
            defaultMessage: "Note privée",
          })}
          isDisabled={areFieldsMissing}
          helperText={intl.formatMessage({
            defaultMessage:
              "Cette note privée ne sera pas visible par le voyageur",
          })}
          withCharactersCounter={true}
          rules={{
            maxLength: 3000,
          }}
          name={`data.bookings.${bookingIndex}.private_comment`}
        />

        <ObserverRHF
          names={[`data.bookings.${bookingIndex}.periods_amount`]}
          render={({ values: [periods_amount] }) => {
            return (
              <>
                {temporaryPeriodsAmount &&
                  temporaryPeriodsAmount !== periods_amount && (
                    <Alert
                      status="info"
                      display="flex"
                      flexDir="column"
                      alignItems="flex-start">
                      <AlertDescription mb="1rem">
                        {intl.formatMessage(
                          {
                            defaultMessage:
                              "Une nouvelle estimation du montant des nuitées à été calculée (Nouveau montant : {newPeriodsAmount} €). Vous pouvez décider de mettre à jour le montant des nuitées de manière automatique en <i>appliquant les changements</i>. Ou ne rien mettre à jour en <i>refusant les changements</i>.",
                          },
                          {
                            newPeriodsAmount: temporaryPeriodsAmount,
                            i: (...chunks) => <i>{chunks}</i>,
                          },
                        )}
                      </AlertDescription>

                      <ButtonGroup>
                        <Button
                          colorScheme="red"
                          onClick={handleRefuseNewPeriodAmount}>
                          {intl.formatMessage({
                            defaultMessage: "Refuser les changements",
                          })}
                        </Button>

                        <Button
                          colorScheme="green"
                          onClick={handleAcceptNewPeriodAmount}>
                          {intl.formatMessage({
                            defaultMessage: "Appliquer les changements",
                          })}
                        </Button>
                      </ButtonGroup>
                    </Alert>
                  )}
              </>
            );
          }}
        />

        <SimpleGrid columns={{ base: 1, md: 3 }} spacing="1rem">
          <Stack spacing=".5rem" opacity=".5">
            <Text>
              {intl.formatMessage({
                defaultMessage: "Nombre de nuits",
              })}
            </Text>

            <ObserverRHF
              names={[
                `data.bookings.${bookingIndex}.checkin`,
                `data.bookings.${bookingIndex}.checkout`,
              ]}
              render={({ values: [checkin, checkout] }) => (
                <Input
                  readOnly={true}
                  isDisabled={areFieldsMissing}
                  value={
                    checkout && checkin
                      ? dayjs(checkout).diff(dayjs(checkin), "days")
                      : ""
                  }
                />
              )}
            />
          </Stack>

          <Stack spacing=".5rem">
            <FormControlRHF
              control={control}
              type="text"
              rules={{
                onChange: (event) => {
                  // Requesting only when the field is not empty to avoid the field to be again by the estimation
                  if (event.target["value"] !== "") {
                    requestEstimate({ forcePeriodsAmount: true });
                  }
                },
              }}
              isDisabled={
                areFieldsMissing ||
                workflow === CARTS_BOOKINGS_WORKFLOW_VALUE_SYSTEM
              }
              name={`data.bookings.${bookingIndex}.periods_amount`}
              label={intl.formatMessage({
                defaultMessage: "Montant des nuitées (en euros)",
              })}
            />

            {!areFieldsMissing &&
              workflow !== CARTS_BOOKINGS_WORKFLOW_VALUE_SYSTEM &&
              getBookingUpdateDifferencePerField({
                fieldName: "periods_amount",
              }) && (
                <BookingRequestChangeFieldMessage
                  newValue={getBookingUpdateDifferencePerField({
                    fieldName: "periods_amount",
                  })}
                  onAcceptBookingUpdateRequestByField={() =>
                    acceptBookingUpdateRequestByField({
                      fieldName: "periods_amount",
                    })
                  }
                />
              )}
          </Stack>

          <FormControlRHF
            control={control}
            type="text"
            isDisabled={
              areFieldsMissing ||
              workflow === CARTS_BOOKINGS_WORKFLOW_VALUE_SYSTEM
            }
            name={`data.bookings.${bookingIndex}.guarantee`}
            label={intl.formatMessage({
              defaultMessage: "Dépôt de garantie (en euros)",
            })}
          />
        </SimpleGrid>
      </>
    );
  },
);
