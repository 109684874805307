import { Flex, Text, VStack } from "@chakra-ui/react";
import { RGrid } from "@raiden/library-ui/components/RGrid";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";
import { memo } from "react";
import { ExpandableContainer } from "../../../components/ExpandableContainer/ExpandableContainer";
import { Icon } from "@raiden/library-ui/components/Icon";
import { FormattedMessage } from "react-intl";
import { HotelsAmenityPopover } from "../../../components/Hotels/AmenityPopover";

export const HotelViewProximityAmenities = memo(
  /**
   * @typedef {object} Props
   * @property {import("@raiden/library-ui/types/Room").RoomAmenity[]} amenities
   */
  /**
   * @param {Props} props
   */
  function HotelViewProximityAmenities({ amenities }) {
    const translate = useTranslate();

    return (
      <ExpandableContainer maxHeight={40 * 3}>
        <RGrid gridGap="16px" minCellWidth="150px">
          {amenities.map((amenity) => (
            <VStack spacing="4px" pr="12px" py="8px" key={amenity.id}>
              <Icon
                icon={amenity.icon ?? "ms_star"}
                size="24px"
                color="gray.500"
              />

              <Flex gap=".5rem" alignItems="center">
                <Text as="span" variant="text-label" textAlign="center">
                  {amenity.distance ? (
                    <FormattedMessage
                      defaultMessage="{name} à {distance} km"
                      values={{
                        name: translate(amenity.name),
                        distance: amenity.distance / 1000,
                      }}
                    />
                  ) : (
                    translate(amenity.name)
                  )}
                </Text>

                <HotelsAmenityPopover roomAmenity={amenity} />
              </Flex>
            </VStack>
          ))}
        </RGrid>
      </ExpandableContainer>
    );
  },
);
