import {
  BOOKINGS_NATURE_VALUE_PAR,
  BOOKINGS_SOURCE_VALUE_CUSTOMER,
} from "../../../../constants/bookings";
import uniqid from "uniqid";

/**
 * @param {object} param0
 * @param {number} [param0.roomId]
 * @param {string} [param0.checkin]
 * @param {string} [param0.checkout]
 * @param {number} [param0.adults]
 * @param {number} [param0.babies]
 * @param {number} [param0.children]
 * @param {number} [param0.pets]
 * @returns {import("../../../../types/Cart").CartCreateOrUpdateBooking}
 */
export function createBookingWithDefaultValue({
  roomId,
  checkin,
  checkout,
  adults,
  babies,
  children,
  pets,
}) {
  /** @type {import("../../../../types/Cart").CartCreateOrUpdateBooking}  */
  return {
    booking_id: "",
    uuid: uniqid(),
    room_name: "",
    room_id: roomId ?? "",
    nature: BOOKINGS_NATURE_VALUE_PAR,
    source: BOOKINGS_SOURCE_VALUE_CUSTOMER,
    checkin: checkin ?? "",
    checkout: checkout ?? "",
    arrival_min: "",
    arrival_max: "",
    departure_min: "",
    departure_max: "",
    adults: adults ?? 1,
    children: children ?? 0,
    babies: babies ?? 0,
    pets: pets ?? 0,
    pets_info: "",
    guarantee: "",
    periods_amount: "",
    public_comment: "",
    private_comment: "",
    services: [],
    discounts: [],
    charges: [],
  };
}
