import {
  AspectRatio,
  Box,
  Button,
  HStack,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import PampaImage from "@raiden/library-ui/components/PampaImage";
import { HOTELS_TYPE_VALUE_UNUSUAL } from "@raiden/library-ui/constants/hotels";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import { memo, useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { LabelRanking } from "../../../components/Common/LabelRanking";
import { SliderImages } from "../../../components/SearchHotels/Cards/VerticalCard/SliderImages";
import { getRoomPrice } from "../../../helpers/rooms/getRoomPrice";
import { HotelViewRoomInfo } from "./RoomInfo";
import { HotelViewRoomModal } from "./RoomModal";
import { HotelViewRoomPrice } from "./RoomPrice";

export const HotelViewRoomCard = memo(
  /**
   * @typedef {object} Props
   * @property {import("@raiden/library-ui/types/Room").Room} room
   * @property {"night" | "week"} durationUnit
   * @property {() => void} onOpenBottomBar
   * @property {import("react-hook-form").UseFormReturn<import("../../CartBookings/Create/Handler").CartBookingsCreateFormValues>} form
   * @property {import("@raiden/library-ui/types/Hotel").HotelType} hotelType
   */
  /**
   * @param {Props} props
   */
  function HotelViewRoomCard({
    room,
    durationUnit,
    onOpenBottomBar,
    form,
    hotelType,
  }) {
    const translate = useTranslate();

    const {
      isOpen: isOpenModal,
      onOpen: onOpenModal,
      onClose: onCloseModal,
    } = useDisclosure();

    const isMobile = useBreakpointValue({ base: true, md: false });

    const handleBookClick = useCallback(() => {
      if (isMobile) {
        onOpenBottomBar();
      }

      form.setValue("room_id", room.id, { shouldDirty: true });
      onCloseModal();
    }, [form, isMobile, onCloseModal, onOpenBottomBar, room.id]);

    const roomPrice = useMemo(() => {
      return getRoomPrice({ room, durationUnit });
    }, [durationUnit, room]);

    return (
      <>
        <Stack
          onClick={onOpenModal}
          cursor="pointer"
          textAlign="left"
          direction={{ base: "column", sm: "row" }}
          spacing="0"
          borderRadius="10px"
          overflow="hidden"
          key={room.id}>
          <Box flexShrink={0} w={{ base: "auto", sm: "315px" }}>
            {room.pictures && room.pictures.length > 1 ? (
              <SliderImages pictures={room.pictures} />
            ) : (
              <AspectRatio
                flexShrink={0}
                ratio={4 / 3}
                w={{ base: "auto", sm: "315px" }}>
                <PampaImage
                  image={room.pictures?.[0]}
                  mode="crop"
                  format="default"
                  placeholderSrc={generateFrontPath({
                    id: "@front.internal-assets",
                    parameters: { filePath: "placeholder-hotel.svg" },
                    includeBasePath: true,
                  })}
                  alt=""
                />
              </AspectRatio>
            )}
          </Box>

          <Stack
            flexGrow={1}
            spacing="0"
            alignSelf="stretch"
            backgroundColor="gray.100"
            px="16px"
            py="12px">
            <Stack flexGrow={1}>
              <HStack alignItems="flex-start">
                <Box flexGrow={1}>
                  {room.name && (
                    <Text variant="text-input" noOfLines={1}>
                      {translate(room.name)}
                    </Text>
                  )}
                </Box>

                {room.last_label_ranking &&
                  hotelType !== HOTELS_TYPE_VALUE_UNUSUAL && (
                    <LabelRanking
                      labelRanking={room.last_label_ranking.value}
                    />
                  )}
              </HStack>

              {room.surface !== null && (
                <Text variant="text-sm" color="gray.500">
                  <FormattedMessage
                    defaultMessage="{surface} m²"
                    values={{
                      surface: room.surface,
                    }}
                  />
                </Text>
              )}

              <HotelViewRoomInfo
                room={room}
                withWCs={false}
                withBabyFriendly={false}
              />

              {room.about && (
                <Text variant="text-xs" noOfLines={2}>
                  {translate(room.about)}
                </Text>
              )}
            </Stack>

            <HStack spacing="8px">
              <Box flexGrow={1}>
                {roomPrice && (
                  <HotelViewRoomPrice
                    price={roomPrice.price}
                    durationUnit={roomPrice.durationUnit}
                  />
                )}
              </Box>

              <Button
                onClick={onOpenModal}
                size="sm"
                colorScheme="brandSecondary">
                <Text color="#fff" variant="button-sm">
                  <FormattedMessage defaultMessage="Plus d'information" />
                </Text>
              </Button>
            </HStack>
          </Stack>
        </Stack>

        <HotelViewRoomModal
          room={room}
          durationUnit={durationUnit}
          isOpen={isOpenModal}
          onClose={onCloseModal}
          handleBookClick={handleBookClick}
          hotelType={hotelType}
        />
      </>
    );
  },
);
