import {
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import { Icon } from "@raiden/library-ui/components/Icon";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";
import { useIntl } from "react-intl";

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/Room").RoomAmenity} roomAmenity
 */

/**
 * @param {Props} props
 */
export function HotelsAmenityPopover({ roomAmenity }) {
  const intl = useIntl();

  const translate = useTranslate();

  if (roomAmenity.about === null) {
    return null;
  }

  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          mx="auto"
          height="1rem"
          width="1rem"
          minWidth="1rem"
          aria-label={intl.formatMessage(
            {
              defaultMessage:
                "En savoir plus sur la caractéristique « {name} »",
            },
            {
              name: translate(roomAmenity.name),
            },
          )}
          variant="unstyled"
          icon={<Icon size="16px" icon="ms_info" color="brandPrimary.300" />}
        />
      </PopoverTrigger>

      {/* Rendering in a portal to prevent cut in indoor and outdoor sections */}

      <Portal>
        <PopoverContent>
          <PopoverArrow />

          <PopoverCloseButton />

          <PopoverBody pr="2rem">{translate(roomAmenity.about)}</PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}
