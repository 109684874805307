import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Text,
  useDisclosure,
  Wrap,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { memo, useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { GuestsInput } from "../../../components/Form/GuestsInput";
import { Select } from "../../../components/Form/Select/Select";
import { Icon } from "../../../components/Icon";
import FormControlRHF from "../../../components/ReactHookForm/FormControlRHF";
import { renderRoomSelectOption } from "../../../components/Room/Select/renderOption";
import { monthValuesMessage } from "../../../constants/time";
import { useConfiguration } from "../../../hooks/useConfiguration";
import { Legend } from "./Legend";

export const Filters = memo(
  /**
   * @typedef {object} Props
   * @property {import("react-hook-form").UseFormReturn<import(".").RoomsVisualizationFormValues>} form
   * @property {Record<number, import("../../../types/Customer").CustomerRoomInfo>} roomsInfo
   * @property {number} currentYear
   * @property {import("react").Dispatch<import("react").SetStateAction<boolean>>} setWasPricingRefreshing
   * @property {number} [maxCapacity]
   */
  /**
   * @param {Props} props
   */
  function Filters({
    form,
    roomsInfo,
    currentYear,
    setWasPricingRefreshing,
    maxCapacity,
  }) {
    const intl = useIntl();
    const roomsOptions = useMemo(() => {
      /** @type {import("../../../components/Form/Select/Select").SelectOption<number, import("../../../components/Room/Select/renderOption").RoomsSelectOptionData>[]} */
      const options = Object.values(roomsInfo).map((roomInfo) => ({
        value: roomInfo.id,
        label: [roomInfo.hlo, roomInfo.name].filter(Boolean).join(" - "),
        data: {
          title: roomInfo.hlo,
          name: roomInfo.name ?? "",
          picture: roomInfo.picture,
        },
      }));
      return options;
    }, [roomsInfo]);
    const { configuration } = useConfiguration();
    const minYear = currentYear - 1;

    const yearMonthOptions = useMemo(() => {
      const yearMonthOptions = [];
      const years =
        configuration.years?.filter(
          (year) => dayjs(year.from).year() >= minYear,
        ) ?? [];
      years.forEach((year) => {
        for (let i = 0; i < 12; i++) {
          const date = dayjs(year.from).month(i);
          yearMonthOptions.push({
            value: date.format("YYYY-MM-DD"),
            label: intl.formatMessage(
              { defaultMessage: "{month} {year}" },
              {
                month: intl.formatMessage(monthValuesMessage, {
                  month: date.format("MMMM").toLowerCase(),
                }),
                year: date.format("YYYY"),
              },
            ),
            data: undefined,
          });
        }
      });
      return yearMonthOptions;
    }, [configuration.years, intl, minYear]);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleClickPreviousMonth = useCallback(() => {
      const currentOptionIndex = yearMonthOptions.findIndex(
        (option) => option.value === form.getValues("yearMonth"),
      );
      form.setValue(
        "yearMonth",
        yearMonthOptions[currentOptionIndex - 1].value,
      );
    }, [form, yearMonthOptions]);

    const handleClickNextMonth = useCallback(() => {
      const currentOptionIndex = yearMonthOptions.findIndex(
        (option) => option.value === form.getValues("yearMonth"),
      );
      form.setValue(
        "yearMonth",
        yearMonthOptions[currentOptionIndex + 1].value,
      );
    }, [form, yearMonthOptions]);

    const renderTrigger = useCallback(
      /** @type {import("../../../components/Form/GuestsInput").GuestsInputRenderTriggerCallback} */
      ({ label }) => (
        <Button
          variant="outline"
          leftIcon={<Icon icon="ms_people" size="24px" color="gray.500" />}
          minW="10.3125rem"
          justifyContent="flex-start">
          <Text as="span" fontWeight="normal">
            {label}
          </Text>
        </Button>
      ),
      [],
    );

    return (
      <Wrap spacing="8px">
        {Object.keys(roomsInfo).length > 1 && (
          <FormControl>
            <FormLabel>
              <FormattedMessage defaultMessage="Hébergement" />
            </FormLabel>

            <FormControlRHF
              control={form.control}
              name="roomId"
              rules={{
                onChange: () => {
                  setWasPricingRefreshing(false);
                },
              }}
              renderWithFormControl={(field) => (
                <Select
                  {...field}
                  options={roomsOptions}
                  w="300px"
                  renderOption={renderRoomSelectOption}
                />
              )}
            />
          </FormControl>
        )}

        <FormControlRHF
          control={form.control}
          name="yearMonth"
          label="Période"
          renderWithFormControl={(field) => (
            <Flex>
              <IconButton
                icon={<Icon icon="ms_chevron_left" />}
                onClick={handleClickPreviousMonth}
                isDisabled={field.value === yearMonthOptions[0]?.value}
                aria-label={intl.formatMessage({
                  defaultMessage: "mois précédent",
                })}
                borderRightRadius="0"
                borderRightWidth="1px"
              />

              <Select
                {...field}
                options={yearMonthOptions}
                borderRadius="0"
                w="180px"
              />

              <IconButton
                icon={<Icon icon="ms_chevron_right" />}
                onClick={handleClickNextMonth}
                isDisabled={
                  field.value ===
                  yearMonthOptions[yearMonthOptions.length - 1]?.value
                }
                aria-label={intl.formatMessage({
                  defaultMessage: "mois suivant",
                })}
                borderLeftRadius="0"
                borderLeftWidth="1px"
              />
            </Flex>
          )}
        />

        <Box>
          <FormControl>
            <FormLabel>
              <FormattedMessage defaultMessage="Capacité" />
            </FormLabel>

            <HStack>
              <GuestsInput
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                maxCapacity={maxCapacity}
                renderTrigger={renderTrigger}
              />

              <Legend />
            </HStack>
          </FormControl>
        </Box>
      </Wrap>
    );
  },
);
