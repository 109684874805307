import {
  cartBookingGetDiscountsForSerialization,
  cartBookingGetServicesForSerialization,
} from "../../serializeCreate";

/**
 * @param {object} param0
 * @param {import("../../../../types/Cart").CartCreateOrUpdateBooking} param0.booking
 * @param {boolean} param0.forcePeriodsAmount
 * @returns {number | "" | undefined}
 */

function getPeriodsAmount({ booking, forcePeriodsAmount }) {
  return forcePeriodsAmount ? booking.periods_amount : undefined;
}

/**
 * @param {object} param0
 * @param {import("../../../../types/Cart").CartCreateOrUpdateBooking} param0.booking
 * @param {keyof typeof import("../../../../constants/carts").CARTS_WORKFLOWS} param0.workflow
 * @param {boolean} [param0.forcePeriodsAmount]
 */
export default function serializeBookingEstimate({
  booking,
  workflow,
  forcePeriodsAmount = false,
}) {
  const isUpdating = booking.booking_id !== "";

  const periodsAmount = getPeriodsAmount({ booking, forcePeriodsAmount });

  const bookingWithServices = {
    ...booking,
    periods_amount: periodsAmount,
    booking_id: isUpdating ? booking.booking_id : undefined,
    uuid: undefined,
    room_name: undefined,
    private_comment: undefined,
    public_comment: undefined,
    services: cartBookingGetServicesForSerialization({
      booking,
      workflow,
    }),
    discounts: cartBookingGetDiscountsForSerialization({ booking, workflow }),
    charges: undefined,
  };

  return {
    data: {
      ...bookingWithServices,
      // We don't send the workflow when updating a booking
      workflow: isUpdating ? undefined : workflow,
    },
  };
}
