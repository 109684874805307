import { useApiError } from "../../../../components/ReactHookForm/ApiError";
import {
  ROOMS_SERVICES_AMOUNT_MODE_VALUE_EURO,
  ROOMS_SERVICES_STRATEGY_VALUE_FREE_QUANTITY,
} from "../../../../constants/roomsServices";
import { apiGetErrorDetail, apiGetErrorFields } from "../../../../helpers/api";
import canRequestEstimate from "../../../../helpers/bookings/estimate/canRequestEstimate";
import useRequest from "../../../../hooks/useRequest";
import generateApiUrl from "../../../../libraries/utils/generateApiUrl";
import serializeBookingEstimate from "../../../../normalizers/carts/bookings/serializeEstimate";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import uniqid from "uniqid";
import getBookingSummary from "../../../../helpers/bookings/getBookingSummary";

/**
 * @typedef {object} RequestEstimateParams
 * @property {boolean} forcePeriodsAmount
 */

/**
 * @typedef {object} EstimateFormFields
 * @property {string} arrival_min
 * @property {string} arrival_max
 * @property {string} departure_min
 * @property {string} departure_max
 * @property {number} periods_amount
 * @property {number} guarantee
 * @property {import("../../../../types/Cart").CartCreateBookingService[]} services
 * @property {import("../../../../types/Cart").CartCreateBookingDiscount[]} discounts
 * @property {import("../../../../types/Cart").CartCreateBookingCharge[]} charges
 */

/**
 * Fields that be set by the estimate endpoint
 * @type {["arrival_min", "arrival_max", "departure_min", "departure_max", "periods_amount", "guarantee", "services", "discounts", "charges"]}
 */
const FIELDS_TO_BE_SET_BY_ESTIMATE = [
  "arrival_min",
  "arrival_max",
  "departure_min",
  "departure_max",
  "periods_amount",
  "guarantee",
  "services",
  "discounts",
  "charges",
];

/**
 * Default values for the estimate fields
 * @type {EstimateFormFields}
 */
const ESTIMATE_VALUES_DEFAULT_VALUES = {
  arrival_min: "",
  arrival_max: "",
  departure_min: "",
  departure_max: "",
  periods_amount: 0,
  guarantee: 0,
  services: [],
  discounts: [],
  charges: [],
};

/**
 * @param {object} param0
 * @param {import("../../../../types/CartBookingEstimation").CartBookingEstimationService} param0.estimateService
 * @param {import("../../../../types/RoomService").RoomService[] | undefined} param0.availableServices
 * @param {boolean} param0.forcePeriodsAmount
 * @returns {{ total: number, amount: number, unitary_amount: number }}
 */
function getAmount({ estimateService, availableServices, forcePeriodsAmount }) {
  const availableService = availableServices?.find(
    (availableService) => estimateService.service_id === availableService.id,
  );

  const amount = estimateService.room_service?.amount ?? estimateService.amount;

  const defaultValues = {
    total: estimateService.total,
    amount,
    unitary_amount: estimateService?.amount,
  };

  if (!forcePeriodsAmount) {
    return defaultValues;
  }

  if (!availableService) {
    return defaultValues;
  }

  const availableServiceUnitaryAmount =
    availableService?.unitary_amount ?? defaultValues.unitary_amount;

  return {
    total: availableServiceUnitaryAmount * estimateService.quantity,
    amount: availableService.amount,
    unitary_amount: availableServiceUnitaryAmount,
  };
}
/**
 * @callback BookingEstimateSetBooking
 * @param {import("../../../../types/Cart").CartCreateOrUpdateBooking} bookingFormValues
 * @returns {void}
 */

/**
 * @callback BookingEstimateGetBooking
 * @returns {import("../../../../types/Cart").CartCreateOrUpdateBooking}
 */

/**
 * @callback BookingEstimateGetPeriodsAmount
 * @returns {number | ""}
 */

/**
 * @callback BookingEstimateGetDiscountList
 * @returns {import("../../../../types/Cart").CartCreateBookingDiscount[]}
 */

/**
 * @callback BookingEstimateDiscountReplaceCallback
 * @param {import("../../../../types/Cart").CartCreateBookingDiscount[]} discounts
 * @returns {void}
 */

/**
 * @callback BookingEstimateChargesReplaceCallback
 * @param {import("../../../../types/Cart").CartCreateBookingCharge[]} charges
 * @returns {void}
 */

/**
 * @callback BookingEstimateServicesReplaceCallback
 * @param {import("../../../../types/Cart").CartCreateBookingService[]} services
 * @returns {void}
 */

/**
 * @callback BookingEstimateResolveApiErrorCallback
 * @param {object} errorFields
 * @param {object} errorDetail
 * @returns {void}
 */

/**
 * @typedef {object} Props
 * @property {BookingEstimateSetBooking} setBooking
 * @property {BookingEstimateGetBooking} getBooking
 * @property {BookingEstimateGetPeriodsAmount} getPeriodsAmount
 * @property {BookingEstimateGetDiscountList} getDiscountList
 * @property {BookingEstimateResolveApiErrorCallback} resolveApiError
 * @property {() => void} clearErrors
 * @property {keyof typeof import("../../../../constants/carts").CARTS_WORKFLOWS} workflow
 * @property {import("react").Dispatch<import("react").SetStateAction<import("../../../../types/Cart").BookingSummary[]>>} [setBookingSummaries]
 * @property {import("react").Dispatch<import("react").SetStateAction<number>>} [setTemporaryPeriodsAmount]
 * @property {import("react").Dispatch<import("react").SetStateAction<import("../../../../types/Cart").CartCreateBookingDiscount[]>>} [setTemporaryDiscountList]
 * @property {import("react").Dispatch<import("react").SetStateAction<import("../../../../types/Cart").CartCreateBookingAvailableService[]>>} setAvailableServices
 * @property {Array<number>} ignoreRequiredServices
 * @property {BookingEstimateDiscountReplaceCallback} bookingDiscountsReplace
 * @property {import("react-hook-form").UseFieldArrayReplace<import("../../../../types/Cart").CartCreateDataFormValues | import("../../../../types/CartBooking").CartBookingUpdateDataFormValues, any>} bookingServicesReplace
 * @property {import("react-hook-form").UseFieldArrayReplace<import("../../../../types/Cart").CartCreateDataFormValues | import("../../../../types/CartBooking").CartBookingUpdateDataFormValues, any>} bookingChargesReplace
 * @property {import("react").Dispatch<import("react").SetStateAction<string | null>>} setApiErrorDetail
 * @property {import("react").Dispatch<import("react").SetStateAction<[]>>} setWarnings
 */

/**
 * @param {Props} param
 */
export function useBookingEstimate({
  setBooking,
  getBooking,
  getPeriodsAmount,
  getDiscountList,
  clearErrors,
  resolveApiError,
  workflow,
  setBookingSummaries,
  setTemporaryPeriodsAmount,
  setTemporaryDiscountList,
  setAvailableServices,
  ignoreRequiredServices,
  bookingDiscountsReplace,
  bookingServicesReplace,
  bookingChargesReplace,
  setApiErrorDetail,
  setWarnings,
}) {
  const { request, isLoading, toastError } = useRequest({
    abortOngoingRequests: true,
  });

  const { resetApiErrors } = useApiError();

  const intl = useIntl();

  /**
   * Function that returns an object with the state of the periods amount
   * @param {object} param0
   * @param {number} param0.currentPeriodsAmount
   * @param {number} param0.temporaryPeriodsAmount
   * @returns {boolean}
   */
  const isPeriodAmountChanged = useCallback(
    ({ currentPeriodsAmount, temporaryPeriodsAmount }) => {
      if (setTemporaryPeriodsAmount && temporaryPeriodsAmount !== null) {
        if (currentPeriodsAmount !== temporaryPeriodsAmount) {
          return true;
        }
      }
      return false;
    },
    [setTemporaryPeriodsAmount],
  );

  /**
   * Function that returns the state of the discount list with the new discounts
   * @param {object} param0
   * @param {import("../../../../types/Cart").CartCreateBookingDiscount[]} param0.currentDiscountList
   * @param {import("../../../../types/Cart").CartCreateBookingDiscount[]} param0.temporaryDiscountList
   * @returns {{ hasChanged: boolean, discountList: import("../../../../types/Cart").CartCreateBookingDiscount[] }}
   */
  const isDiscountListChanged = useCallback(
    ({ currentDiscountList, temporaryDiscountList }) => {
      const newDiscounts = temporaryDiscountList.filter((discount) => {
        return !currentDiscountList.some(
          (currentDiscount) => currentDiscount.name === discount.name,
        );
      });

      return {
        hasChanged: Boolean(
          newDiscounts.length > 0 && setTemporaryDiscountList,
        ),
        discountList: newDiscounts,
      };
    },
    [setTemporaryDiscountList],
  );

  /**
   * Function that updates the form with the values of the estimate. It will update the services, discounts, and booking info
   */
  const updateFormWithEstimateValues = useCallback(
    /**
     * @param {object} param0
     * @param {import("../../../../types/Cart").CartCreateOrUpdateBooking} param0.bookingFormValues
     * @param {Partial<import("../../../../types/CartBookingEstimation").BookingEstimation | undefined>} param0.estimate
     * @param {import("../../../../types/CartBookingEstimation").BookingEstimationMeta | undefined} param0.meta
     * @param {boolean} param0.forcePeriodsAmount
     */
    ({ bookingFormValues, estimate, meta, forcePeriodsAmount }) => {
      if (!estimate) {
        return;
      }

      // Services already in the booking
      /** @type {import("../../../../types/Cart").CartCreateBookingService[]} */
      const estimateServices = (estimate?.services ?? []).map((service) => {
        const quantity = service.quantity;

        const amountCompute = getAmount({
          estimateService: service,
          availableServices: meta?.available_services,
          forcePeriodsAmount,
        });

        return {
          ...amountCompute,
          service_id: service.service_id,
          booking_id: null,
          name: service.name,
          quantity,
          discount: service.discount ?? "",
          about: service.about,
          strategy: service.room_service?.strategy ?? "",
          amount_mode:
            service.room_service?.amount_mode ??
            ROOMS_SERVICES_AMOUNT_MODE_VALUE_EURO,
          min_quantity: service.room_service?.min_quantity ?? "",
          max_quantity: service.room_service?.max_quantity ?? "",
          is_required: service.room_service?.is_required ?? false,
          uuid: uniqid(),
        };
      });

      // Services that are available in addition to the ones already in the booking
      /** @type {import("../../../../types/Cart").CartCreateBookingAvailableService[]} */
      const availableServices = (meta?.available_services ?? []).map(
        (service) => {
          return {
            service_id: service.id,
            name: service.name,
            quantity:
              service.strategy === ROOMS_SERVICES_STRATEGY_VALUE_FREE_QUANTITY
                ? 0
                : service.min_quantity
                ? service.min_quantity
                : 1,
            min_quantity: service.min_quantity ?? "",
            max_quantity: service.max_quantity ?? "",
            amount: service.amount ?? "",
            unitary_amount: service.unitary_amount ?? 0,
            amount_mode: service.amount_mode,
            strategy: service.strategy,
            discount: "",
            about: service.about,
            total: (service?.min_quantity ?? 1) * (service.unitary_amount ?? 0),
            is_required: service.is_required,
          };
        },
      );

      setAvailableServices(availableServices);

      // Loop over the available services to add the required services that are not already in the form
      availableServices.forEach((availableService) => {
        if (
          availableService.is_required &&
          !ignoreRequiredServices.includes(Number(availableService.service_id))
        ) {
          const serviceAlreadyInBooking = estimateServices.find(
            (service) => service.service_id === availableService.service_id,
          );
          if (!serviceAlreadyInBooking) {
            estimateServices.push({
              service_id: availableService.service_id,
              name: availableService.name,
              quantity: availableService.quantity,
              min_quantity: availableService.min_quantity,
              max_quantity: availableService.max_quantity,
              amount: availableService.amount,
              unitary_amount: availableService.unitary_amount,
              amount_mode: availableService.amount_mode,
              is_required: availableService.is_required,
              about: availableService.about,
              strategy: availableService.strategy,
              total: availableService.total,
              discount: availableService.discount,
              uuid: uniqid(),
            });
          }
        }
      });

      /* Filter the estimateServices to keep only the services that have either :
       * - no service_id (service added via the append button)
       * - a service_id that is also present in the available services (because available services can change depending on date, room, etc)
       */

      /** @type {import("../../../../types/Cart").CartCreateBookingService[]} */
      const filteredEstimateServices = estimateServices?.filter(
        (service) =>
          !service.service_id ||
          availableServices.some(
            (availableService) =>
              availableService.service_id === service.service_id,
          ),
      );

      /** @type {import("../../../../types/Cart").CartCreateBookingDiscount[]} */
      const estimateDiscounts = (estimate?.discounts ?? []).map((discount) => ({
        amount: discount.amount,
        name: discount.name,
        discount_id: discount.discount_id ?? "",
      }));

      /** @type {import("../../../../types/Cart").CartCreateBookingCharge[]} */
      const estimateCharges = (estimate?.charges ?? []).map((charge) => ({
        amount: charge.amount,
        amount_tax_exc: charge.amount_tax_exc,
        amount_tax_inc: charge.amount_tax_inc,
        discount: charge.discount,
        name: charge.name,
        quantity: charge.quantity,
        vat_rate: charge.vat_rate,
        about: charge.about,
      }));

      // Create a new object with the estimate values of the ESTIMATED_BOOKING_FIELDS
      // and the services with the services available in the estimate and the services already in the booking
      /** @type {EstimateFormFields} */
      const estimateValues = FIELDS_TO_BE_SET_BY_ESTIMATE.reduce((acc, key) => {
        if (key === "services") {
          return {
            ...acc,
            services:
              filteredEstimateServices.map((service) => ({
                service_id: service.service_id ?? "",
                name: service.name ?? "",
                discount: service.discount ?? "",
                min_quantity: service?.min_quantity ?? "",
                max_quantity: service?.max_quantity ?? "",
                quantity:
                  service?.min_quantity !== ""
                    ? service.min_quantity
                    : service.quantity,
                amount: service.amount ?? "",
                unitary_amount: service.unitary_amount ?? "",
                amount_mode: service.amount_mode ?? "",
                is_required: service?.is_required ?? false,
                about: service.about ?? "",
                strategy: service?.strategy ?? "",
                total: service.total ?? "",
                uuid: uniqid(),
              })) ?? [],
          };
        } else if (key === "discounts") {
          return {
            ...acc,
            discounts: estimateDiscounts?.map((discount) => ({
              uuid: uniqid(),
              discount_id: discount.discount_id,
              name: discount.name,
              amount: discount.amount,
            })),
          };
        } else if (key === "charges") {
          return {
            ...acc,
            charges: estimateCharges?.map((charge) => ({
              about: charge.about,
              amount: charge.amount,
              amount_tax_exc: charge.amount_tax_exc,
              amount_tax_inc: charge.amount_tax_inc,
              discount: charge.discount,
              name: charge.name,
              quantity: charge.quantity,
              vat_rate: charge.vat_rate,
            })),
          };
        } else {
          return {
            ...acc,
            [key]: estimate?.[key],
          };
        }
      }, ESTIMATE_VALUES_DEFAULT_VALUES);

      // Sorting the services to keep the same order as the use field array
      const sortedEstimateServices = estimateValues["services"].sort(
        (a, b) =>
          bookingFormValues.services.indexOf(a) -
          bookingFormValues.services.indexOf(b),
      );

      // Current periods amount in the form
      const currentPeriodsAmount = getPeriodsAmount();

      const hasPeriodAmountChanged = isPeriodAmountChanged({
        currentPeriodsAmount: Number(currentPeriodsAmount),
        temporaryPeriodsAmount: estimateValues["periods_amount"],
      });

      // If the period amount has changed (in update booking only)
      if (hasPeriodAmountChanged) {
        // We call the callback to pop up the alert to ask the user if he wants to update the periods amount or not
        setTemporaryPeriodsAmount?.(estimateValues["periods_amount"]);
      }

      const currentDiscountList = getDiscountList();

      const hasDiscountListChanged = isDiscountListChanged({
        currentDiscountList,
        temporaryDiscountList: estimateValues["discounts"],
      });

      // If the discount list has changed (in update booking only)
      if (hasDiscountListChanged.hasChanged) {
        // We call the callback to pop up the alert to ask the user if he wants to update the discount list or not
        setTemporaryDiscountList?.(hasDiscountListChanged.discountList);
      }
      // Set the booking value in the form
      setBooking({
        ...getBooking(),
        ...estimateValues,
        // When the periods amount we keep the current periods amount or discount list in the form for now
        // and we will update it after the user has confirmed if he wants to update it or not via the two alerts
        periods_amount: hasPeriodAmountChanged
          ? currentPeriodsAmount
          : estimateValues["periods_amount"],
      });

      bookingDiscountsReplace(
        hasDiscountListChanged.hasChanged
          ? currentDiscountList
          : estimateValues["discounts"],
      );

      bookingChargesReplace(estimateValues["charges"]);

      bookingServicesReplace(sortedEstimateServices);
    },
    [
      setAvailableServices,
      getPeriodsAmount,
      isPeriodAmountChanged,
      getDiscountList,
      isDiscountListChanged,
      setBooking,
      getBooking,
      bookingDiscountsReplace,
      bookingChargesReplace,
      bookingServicesReplace,
      ignoreRequiredServices,
      setTemporaryPeriodsAmount,
      setTemporaryDiscountList,
    ],
  );

  /**
   * Request the estimate for the booking
   */
  const requestEstimate = useCallback(
    /**
     * @param {RequestEstimateParams} [data]
     */
    (data) => {
      const forcePeriodsAmount = data?.forcePeriodsAmount ?? false;

      const bookingFormValues = getBooking();

      if (!canRequestEstimate({ booking: bookingFormValues })) {
        return;
      }

      request(
        generateApiUrl({
          id: "@cartsBookings.estimate",
        }),
        {
          method: "POST",
          body: serializeBookingEstimate({
            booking: bookingFormValues,
            workflow,
            forcePeriodsAmount,
          }),
        },
      )
        .then((response) => {
          clearErrors();
          resetApiErrors();

          const responseValues = FIELDS_TO_BE_SET_BY_ESTIMATE.reduce(
            (acc, key) => ({
              ...acc,
              [key]: response.data?.[key],
            }),
            {},
          );
          /** @type {import("../../../../types/CartBookingEstimation").BookingEstimation} */
          const data = response.data;
          /** @type {import("../../../../types/CartBookingEstimation").BookingEstimationMeta} */
          const meta = response.meta;
          // Sending set fields to be updated by estimate and the meta to have availables services
          updateFormWithEstimateValues({
            bookingFormValues,
            estimate: responseValues,
            meta,
            forcePeriodsAmount,
          });

          const bookingSummary = getBookingSummary({
            data,
            bookingFormValues: bookingFormValues,
          });

          setBookingSummaries?.((previousBookingSummaries) => {
            return previousBookingSummaries.map((summary) =>
              summary.uuid === bookingSummary.uuid ? bookingSummary : summary,
            );
          });

          if (response.data?.meta?.warnings) {
            setWarnings(response.data?.meta?.warnings);
          } else {
            setWarnings((previousWarnings) => {
              if (previousWarnings.length === 0) {
                return previousWarnings;
              }
              return [];
            });
          }
          setApiErrorDetail(null);
        })
        .catch((response) => {
          clearErrors();
          resetApiErrors();

          const errorDetail = apiGetErrorDetail({ error: response });

          setApiErrorDetail(apiGetErrorDetail({ error: response }));

          const errorFields = apiGetErrorFields({ error: response });

          // Resolve api error callback so we don't have to send the form to it
          resolveApiError(errorFields, errorDetail);

          // Condition to prevent error from signal abort to trigger toast notification
          if (response?.body?.errors) {
            toastError({
              title: intl.formatMessage({
                defaultMessage:
                  "Une erreur est survenue lors de l'estimation du tarif du séjour",
              }),
            });
          }
        });
    },
    [
      getBooking,
      request,
      workflow,
      clearErrors,
      resetApiErrors,
      updateFormWithEstimateValues,
      setBookingSummaries,
      setApiErrorDetail,
      setWarnings,
      resolveApiError,
      toastError,
      intl,
    ],
  );

  return {
    requestEstimate,
    isLoading,
  };
}
